import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import moment from 'moment'

import { Button, Text } from '@junglescout/edna'

import { reactivateMembership } from 'actions/account'
import { createBanner, removeBanner } from 'actions/banners'

const calculateDaysUntilCancel = periodEnd => {
  const oneDay = 1000 * 60 * 60 * 24
  const currentDate = new Date().getTime()
  const endDate = new Date(periodEnd).getTime()

  return Math.round((endDate - currentDate) / oneDay)
}

export const MembershipStatusBanners = () => {
  const { t } = useTranslation('account')

  const dispatch = useDispatch()

  const {
    membershipAdmin,
    membershipStatus,
    payment_plan: paymentPlan,
    next_invoice_date: nextInvoiceDate
  } = useSelector(state => state.globalData.membershipInfo)

  const {
    reactivate: { isLoading }
  } = useSelector(state => state.account)

  const numberOfDaysUntilCancel = calculateDaysUntilCancel(nextInvoiceDate)

  const isWithinShowPeriod =
    paymentPlan?.toLowerCase() === 'monthly'
      ? numberOfDaysUntilCancel <= 7
      : numberOfDaysUntilCancel <= 30

  const renderReactivateBanner = useCallback(() => {
    return (
      <>
        {t(
          'account:reactivateMembershipBanner.message',
          'You have access until the end of this billing cycle ({{invoiceDate}}).',
          { invoiceDate: moment(nextInvoiceDate).format('MMMM D, YYYY') }
        )}
        {membershipAdmin && (
          <Button
            mini
            isLoading={isLoading}
            onClick={() => dispatch(reactivateMembership())}>
            {t('account:reactivateMembershipBanner.cta', 'Resume Membership')}
          </Button>
        )}
      </>
    )
  }, [dispatch, membershipAdmin, nextInvoiceDate, isLoading, t])

  const renderPaymentIssueBanner = useCallback(() => {
    return (
      <div>
        <Trans t={t} i18nKey="account:paymentIssueBanner.message">
          There is a payment issue with your credit card. Please update your
          payment&nbsp;
          <Text
            variant="bodyMd"
            element="a"
            href="#/account/billing"
            color="inherit"
            underline>
            here
          </Text>
          &nbsp;to avoid disruption to your service.
        </Trans>
      </div>
    )
  }, [t])

  useEffect(() => {
    if (membershipStatus === 0 && isWithinShowPeriod && nextInvoiceDate) {
      dispatch(removeBanner('payment-issue'))
      dispatch(
        createBanner({
          id: 'reactivate-membership',
          type: 'warning',
          title: t(
            'account:reactivateMembershipBanner.title',
            'Your plan is set to cancel:'
          ),
          message: renderReactivateBanner()
        })
      )
    } else if (membershipStatus === 2) {
      dispatch(removeBanner('reactivate-membership'))
      dispatch(
        createBanner({
          id: 'payment-issue',
          type: 'warning',
          message: renderPaymentIssueBanner()
        })
      )
    }
  }, [
    t,
    dispatch,
    membershipStatus,
    nextInvoiceDate,
    isWithinShowPeriod,
    renderReactivateBanner,
    renderPaymentIssueBanner
  ])

  return null
}
