import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonType } from '@junglescout/edna'

import { createBanner, removeBanner } from 'actions/banners'
import { ConnectToAmazonButton } from 'src/js/containers/common/ConnectToAmazonButton'
import { sendCtaClickEvent } from 'helpers/segment'
import { useTranslation } from 'react-i18next'

const BANNER_ID_MWS_SYNC = 'mws-sync-prompt'

const ONE_WEEK = 7 * 24 * 60 * 60

export const MwsStatusBanners = () => {
  const { t } = useTranslation('mwsSync')
  const dispatch = useDispatch()

  const { amazonSellerAccounts, isLoading } = useSelector(
    state => state.amazonSellerAccounts
  )
  const isAmazonSeller = useSelector(
    state => state.globalData.user.is_amazon_seller
  )

  const hasAccount = amazonSellerAccounts?.length > 0
  const showSyncMwsBanner = isAmazonSeller && !isLoading && !hasAccount

  const renderSyncMwsMessage = useCallback(() => {
    return (
      <>
        {t(
          'mwsSync:StatusBanner.message',
          'by integrating Jungle Scout and Amazon Seller Central'
        )}{' '}
        <ConnectToAmazonButton
          btnType={ButtonType.SECONDARY_BLUE}
          btnText={t(
            'mwsSync:StatusBanner.cta',
            'Integrate your Amazon Account'
          )}
          onClick={() => {
            sendCtaClickEvent({
              destination: 'modal',
              text: 'Integrate your Amazon Account',
              type: 'banner'
            })
          }}
          mini
        />
      </>
    )
  }, [t])

  useEffect(() => {
    if (showSyncMwsBanner) {
      dispatch(
        createBanner({
          id: BANNER_ID_MWS_SYNC,
          type: 'info',
          title: t('mwsSync:StatusBanner.title', 'Finish setting your account'),
          dismissible: true,
          showAfter: ONE_WEEK,
          message: renderSyncMwsMessage(),
          onDismiss: () => {
            sendCtaClickEvent({
              destination: 'modal',
              text: 'Dismiss - Integrate your Amazon Account',
              type: 'banner'
            })
          }
        })
      )
    } else {
      dispatch(removeBanner(BANNER_ID_MWS_SYNC))
    }
  }, [t, dispatch, renderSyncMwsMessage, showSyncMwsBanner])

  return null
}
